export const appointmentConstants = {
  GET_TESTS_SUCCESS: 'TESTS_SUCCESS',
  GET_TESTS_FAILURE: 'TESTS_FAILURE',
  GET_TIMESLOTS_SUCCESS: 'TIMESLOTS_SUCCESS',
  GET_TIMESLOTS_FAILURE: 'TIMESLOTS_FAILURE',
  GET_CURRENT_APPOINTMENTS_SUCCESS: 'CURRENT_APPOINTMENTS_SUCCESS',
  GET_CURRENT_APPOINTMENTS_FAILURE: 'CURRENT_APPOINTMENTS_FAILURE',
  GET_PAST_APPOINTMENTS_SUCCESS: 'PAST_APPOINTMENTS_SUCCESS',
  GET_PAST_APPOINTMENTS_FAILURE: 'PAST_APPOINTMENTS_FAILURE',
  CREATE_APPOINTMENT_SUCCESS: 'CREATE_APPOINTMENT_SUCCESS',
  CREATE_APPOINTMENT_FAIURE: 'CREATE_APPOINTMENT_FAIURE',
  UPDATE_APPOINTMENT_SUCCESS: 'UPDATE_APPOINTMENT_SUCCESS',
  UPDATE_APPOINTMENT_FAIURE: 'UPDATE_APPOINTMENT_FAIURE',
  STORE_APPOINTMENT: 'STORE_APPOINTMENT',
  LOAD_START: 'LOAD_START_ACTION',
  LOAD_END: 'LOAD_END_ACTION',
};
