export const careersConstants = {
	GET_CREERS_LIST_SUCCESS: 'CREERS_SUCCESS',
	GET_CREERS_LIST_FAILURE: 'CREERS_FAILURE',
	GET_DESCRIPTION_SUCCESS: 'DESCRIPTION_SUCCESS',
	GET_DESCRIPTION_FAILURE: 'DESCRIPTION_FAILURE',
	GET_APPLY_FOR_JOB_SUCCESS: 'FOR_JOB_SUCCESS',
	GET_APPLY_FOR_JOB_FAILURE: 'APPLY_FOR_JOB_FAILURE',
	GET_REVIEW_SUCCESS: 'REVIEW_SUCCESS',
	GET_REVIEW_FAILURE: 'REVIEW_FAILURE',
	GET_PICTURE_SUCCESS: 'PICTURE_SUCCESS',
	GET_PICTURE_FAILURE: 'PICTURE_FAILURE',
	SERVICE: 'SERVICE'
};
